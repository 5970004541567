import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_9/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-9-1"}}) {
        body
      },
      audio: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-9-1-audio"}}) {
        excerpt(pruneLength: 10000)
      },
    }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
