import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_4/sub_9/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    file(relativePath: { eq: "chapter_4/sub_9/mobile/4-9-2-bg.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
    zagorska: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "4-9-2-zagorska"}}) {
      body
    },
    wittek: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "4-9-2-wittek"}}) {
      body
    },
    gertz: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "4-9-2-gertz"}}) {
      body
    },
    pelczynska: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "4-9-2-pelczynska"}}) {
      body
    },
    zagorskaImage: file(relativePath: { eq: "chapter_4/sub_9/4-9-2-zagorska.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
    wittekImage: file(relativePath: { eq: "chapter_4/sub_9/4-9-2-wittek.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
    gertzImage: file(relativePath: { eq: "chapter_4/sub_9/4-9-2-gertz.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
    pelczynskaImage: file(relativePath: { eq: "chapter_4/sub_9/4-9-2-pelczynska.png"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
